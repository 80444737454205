import { IonItem, IonLabel, IonInput, IonToggle } from "@ionic/react";

import EmbededVideo from "../components/EmbededVideo";
import { imageProxy } from "./helpers";

const FormValue = ({ value, label, type, last = false }) => {
  const lines = !last ? "full" : "none";

  if (type === "text") {
    return (
      <IonItem lines={lines}>
        <IonInput
          label={label}
          labelPlacement="stacked"
          value={value}
          readonly
        />
      </IonItem>
    );
  }
  if (type === "boolean") {
    return (
      <IonItem lines={lines}>
        <IonToggle
          label={label}
          labelPlacement="stacked"
          disabled
          checked={value}
        />
      </IonItem>
    );
  }
  if (type === "select") {
    return (
      <IonItem lines={lines}>
        <IonInput
          label={label}
          labelPlacement="stacked"
          value={value}
          readonly
        />
      </IonItem>
    );
  }
  if (type === "body") {
    return (
      <IonItem lines={lines}>
        <IonLabel position="stacked">{label}</IonLabel>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "16px",
            width: "100%",
          }}
          dangerouslySetInnerHTML={{ __html: value.processed }}
        />
      </IonItem>
    );
  }
  if (type === "term") {
    return (
      <IonItem lines={lines}>
        <IonInput
          label={label}
          labelPlacement="stacked"
          value={value?.map((term) => term.name).join(", ")}
          readonly
        />
      </IonItem>
    );
  }
  if (type === "media") {
    const type = value?.field_media_oembed_video ? "video" : "image";
    return (
      <IonItem lines={lines}>
        <IonLabel>
          <h3>{label}</h3>

          {type === "video" && (
            <div className="media-selector--video-preview">
              <EmbededVideo src={value.field_media_oembed_video} />
            </div>
          )}

          {type === "image" && (
            <div className="media-selector--image-preview">
              <img
                src={imageProxy(value?.thumbnail.image_style_uri.media_library)}
                alt=""
              />
            </div>
          )}
        </IonLabel>
      </IonItem>
    );
  }
  if (type === "workout-blocks") {
    return (
      <div className="app-block-selector">
        {value.map((block, blockIndex) => (
          <div key={blockIndex}>
            <IonItem className="level-0">
              <IonLabel>{block.field_title ?? "Block"}</IonLabel>
            </IonItem>
            {block.field_workout_block_exercises.map(
              (exercise, exerciseIndex) => (
                <IonItem key={exerciseIndex} className="level-1">
                  <IonLabel>{exercise.title}</IonLabel>
                </IonItem>
              )
            )}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default FormValue;
