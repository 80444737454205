import {
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  useIonRouter,
} from "@ionic/react";
import {
  peopleOutline,
  barbellOutline,
  listOutline,
  folderOpenOutline,
  logOutOutline,
  documentTextOutline,
  settingsOutline,
} from "ionicons/icons";
import { useDynamicDataAuthentication } from "@understandit/dynamic-data-react";

const Menu = () => {
  const pages = [
    {
      title: "Användare",
      icon: peopleOutline,
      link: "/c/admin/users",
    },
    {
      title: "Programmeringar",
      icon: folderOpenOutline,
      link: "/c/admin/program",
    },
    {
      title: "Träningspass",
      icon: listOutline,
      link: "/c/admin/workout",
    },
    {
      title: "Övningar",
      icon: barbellOutline,
      link: "/c/admin/exercise",
    },
    {
      title: "Notiser",
      icon: documentTextOutline,
      link: "/c/admin/notification",
    },
    {
      title: "Inställningar",
      icon: settingsOutline,
      link: "/c/admin/settings",
    },
  ];

  const { logout } = useDynamicDataAuthentication("auth");
  const router = useIonRouter();

  const doLogout = () => {
    logout();
    router.push("/login", "root");
  };

  return (
    <IonContent>
      <IonList>
        {pages.map((page) => (
          <IonItem key={page.title} routerLink={page.link}>
            <IonIcon icon={page.icon} slot="start" />
            {page.title}
          </IonItem>
        ))}
        <IonItem button onClick={doLogout}>
          <IonIcon icon={logOutOutline} slot="start" />
          Logga ut
        </IonItem>
      </IonList>
    </IonContent>
  );
};

export default Menu;
