import {
  IonList,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";

import { useFormState } from "../form/hooks";
import { pageConfig } from "../settings";
import { useContentProvider } from "../providers/content-provider";
import FormValue from "../form/FormValue";
import { closeOutline } from "ionicons/icons";

const PreviewModal = ({ id, type, onDone }) => {
  const entityConfig = pageConfig[type];
  const { Provider, entity } = useContentProvider(id, type);
  const [formState] = useFormState(entityConfig.fields, entity, true);

  return (
    <>
      <Provider />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton onClick={onDone}>
              Stäng
              <IonIcon icon={closeOutline} slot="end" />
            </IonButton>
          </IonButtons>
          <IonTitle>{entity?.title ?? "Laddar..."}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {entityConfig.fields.map((field, i, fields) => (
            <FormValue
              key={field.field}
              label={field.label}
              type={field.type}
              config={field}
              value={formState[field.field]}
              last={i === fields.length - 1}
            />
          ))}
        </IonList>
      </IonContent>
    </>
  );
};

export default PreviewModal;
