import { Redirect, Route } from "react-router-dom";
import {
  setupIonicReact,
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  IonMenu,
  IonContent,
  IonButton,
  useIonRouter,
  IonSpinner,
} from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
import {
  useDynamicDataAuthentication,
  useZeusDataProvider,
} from "@understandit/dynamic-data-react";

import { getSetting } from "./settings";
import { ConnectorProvider } from "./connector-context";
import { useConnector } from "./connector-context";

import Login from "./pages/Login";
import Menu from "./pages/Menu";

import AdminEntityList from "./pages/admin/AdminEntityList";
import AdminEntityAdd from "./pages/admin/AdminEntityAdd";
import AdminEntityEdit from "./pages/admin/AdminEntityEdit";
import AdminEntityCopy from "./pages/admin/AdminEntityCopy";
import AdminUsers from "./pages/admin/AdminUsers";
import AppConnector from "./form/AppConnector";
import AdminUserEdit from "./pages/admin/AdminUserEdit";
import CenterCenter from "./components/CenterCenter";
import AdminSettings from "./pages/admin/AdminSettings";
import AdminSubscription from "./pages/admin/AdminSubscription";

setupIonicReact();

const routes = [
  { path: "/c/admin/users", Component: AdminUsers },
  { path: "/c/admin/users/:id", Component: AdminUserEdit },
  { path: "/c/admin/subscription/:id", Component: AdminSubscription },
  { path: "/c/admin/settings", Component: AdminSettings },
];

for (const type of ["program", "workout", "exercise", "notification"]) {
  routes.push({
    path: `/c/admin/:type(${type})`,
    Component: AdminEntityList,
  });
  routes.push({
    path: `/c/admin/:type(${type})/:id`,
    Component: AdminEntityEdit,
  });
  routes.push({
    path: `/c/admin/:type(${type})/:id/copy`,
    Component: AdminEntityCopy,
  });
  routes.push({
    path: `/c/admin/:type(${type})/add`,
    Component: AdminEntityAdd,
  });
}

const UserDataSources = () => {
  const { data: userId } = useZeusDataProvider("user-id", "");

  const termProviders = [
    { id: "program_tags", limit: 50 },
    { id: "program_tools", limit: 50 },
    { id: "program_goals", limit: 50 },
    { id: "program_categories", limit: 50 },
    { id: "workout_tags", limit: 200 },
    { id: "exercise_tags", limit: 50 },
    { id: "exercise_tools", limit: 50 },
    { id: "access", limit: 50 },
  ];

  return (
    <>
      <zeus-data-provider id="user-id">
        <zeus-data-source-json
          url={`${getSetting("BACKEND")}/api/v4`}
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        <zeus-data-processor-jmespath filter="meta.links.me.meta.id"></zeus-data-processor-jmespath>
      </zeus-data-provider>

      {userId && (
        <zeus-data-provider id="user">
          <zeus-data-source-jsonapi
            url={`${getSetting(
              "BACKEND"
            )}/api/v4/user/user/${userId}?include=roles`}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      )}

      {termProviders.map(({ id, limit }) => (
        <zeus-data-provider id={id} listen-tags={id} key={id}>
          <zeus-data-source-jsonapi
            url={`${getSetting(
              "BACKEND"
            )}/api/v4/taxonomy_term/${id}?page[limit]=${limit}`}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      ))}
    </>
  );
};

const AuthEnv = () => {
  const { connectorIsOpen } = useConnector();
  const { data: user } = useZeusDataProvider("user", "");
  const { logout, isAuthenticated } = useDynamicDataAuthentication("auth");
  const router = useIonRouter();

  const isLoading = !Boolean(user);
  const hasAccess =
    !isLoading &&
    isAuthenticated &&
    user?.roles?.some((x) => x.drupal_internal__id === "app_admin");

  const doLogout = () => {
    logout();
    router.push("/login", "root");
  };

  return (
    <>
      {isLoading && (
        <CenterCenter>
          <IonSpinner color="primary" />
        </CenterCenter>
      )}

      {hasAccess && (
        <IonSplitPane contentId="main">
          <IonMenu contentId="main" side="start" menuId="main-menu">
            <Menu />
          </IonMenu>
          <IonRouterOutlet id="main">
            {routes.map((r) => (
              <Route path={r.path} exact key={r.path}>
                <r.Component />
              </Route>
            ))}
          </IonRouterOutlet>
          <IonMenu
            side="end"
            contentId="main"
            disabled={!connectorIsOpen}
            menuId="connector-menu"
          >
            <IonContent>
              <AppConnector />
            </IonContent>
          </IonMenu>
        </IonSplitPane>
      )}

      {!isLoading && !hasAccess && (
        <IonButton onClick={doLogout}>Try again</IonButton>
      )}

      <UserDataSources />
    </>
  );
};

const App = () => {
  const { isAuthenticated } = useDynamicDataAuthentication("auth");
  return (
    <IonApp>
      <ConnectorProvider>
        <IonReactHashRouter>
          <IonRouterOutlet>
            <Route path={`/login`} exact>
              {!isAuthenticated ? <Login /> : <Redirect to="/c/admin/users" />}
            </Route>
            <Route path={`/c`}>
              <AuthEnv />
            </Route>
            <Redirect from="/" to="/login" exact />
          </IonRouterOutlet>
        </IonReactHashRouter>
        <zeus-data-provider id="auth" no-cache>
          <zeus-data-source-oauth
            url={`${getSetting("BACKEND")}/oauth/token`}
            client-id="d4e01a66-1fb1-41c2-8e69-ceea84a3adf0"
            grant-type="password"
            client-secret="eb9c7404-743a-4e92-91dc-105c520a6d5c"
            scope="app_admin"
          ></zeus-data-source-oauth>
        </zeus-data-provider>
      </ConnectorProvider>
    </IonApp>
  );
};

export default App;
