const defaultSettings = {
  APP_NAME: "Mized",
  BACKEND: "http://localhost:3000",
};

let settings = {};

export async function loadSettings() {
  try {
    const data = JSON.parse(document.getElementById("root")?.dataset?.settings);
    if (data) {
      settings = {
        ...defaultSettings,
        ...data,
      };
    } else {
      throw new Error("Could not parse settings.");
    }
  } catch (e) {
    console.warn("No settings found, using defaults.");
    settings = { ...defaultSettings };
  }
}

export function getSetting(name) {
  return settings?.[name];
}

export const pageConfig = {
  settings: {
    fields: [
      // Section: Login and Register.
      {
        label: "Login and Register",
        type: "separator",
      },
      {
        field: "field_settings_terms_link",
        label: "Terms link",
        type: "link",
      },

      // Section: Frontpage.
      {
        label: "Frontpage",
        type: "separator",
      },
      {
        field: "field_settings_welcome_headline",
        label: "Welcome headline",
        type: "text",
      },
      {
        field: "field_settings_welcome_text",
        label: "Welcome headline",
        type: "longtext",
      },
      {
        field: "field_settings_f_links",
        type: "multiple",
        subType: "link",
        showTitle: true,
        label: "Links",
      },

      // Section: workout feedback.
      {
        label: "Workout feedback",
        type: "separator",
      },
      {
        field: "field_settings_fb_form_headline",
        label: "Feedback form headline",
        type: "text",
      },
      {
        field: "field_settings_fb_form_text",
        label: "Feedback form text",
        type: "longtext",
      },

      // Section: Profile.
      {
        label: "Profil",
        type: "separator",
      },
      {
        field: "field_settings_handle_sub_ios",
        label: "Handle subscription iOS",
        type: "text",
      },
      {
        field: "field_settings_handle_sub_androi",
        label: "Handle subscription android",
        type: "text",
      },
      {
        field: "field_settings_handle_sub_subeng",
        label: "Handle subengine subscription link",
        type: "text",
      },
      {
        field: "field_settings_p_action_link",
        label: "Action link",
        type: "text",
      },
      {
        field: "field_settings_p_action_link_tex",
        label: "Action link text",
        type: "text",
      },

      // Section: New subscription texts
      {
        label: "New subscription texts",
        type: "separator",
      },
      {
        label: "Promo popup headline",
        type: "text",
        field: "field_settings_promo_headline2",
      },
      {
        label: "Promo popup text",
        type: "longtext",
        field: "field_settings_promo_text2",
      },
      {
        label: "Promo popup link",
        type: "text",
        field: "field_settings_promo_link2",
      },
      {
        label: "Promo popup link text",
        type: "text",
        field: "field_settings_promo_link_text2",
      },
      {
        label: "Promo banner headline",
        type: "text",
        field: "field_settings_banner_headline2",
      },
      {
        label: "Promo banner text",
        type: "longtext",
        field: "field_settings_banner_text2",
      },
      {
        label: "promo banner link",
        type: "text",
        field: "field_settings_banner_link2",
      },
      {
        label: "Promo banner link text",
        type: "text",
        field: "field_settings_banner_link_text2",
      },
    ],
  },
  profile_client: {
    listTitle: "Användare",
    editTitle: "Redigera användare",
    showAdd: false,
    fields: [
      {
        field: "field_name",
        label: "Namn",
        type: "text",
        required: true,
      },
      {
        field: "field_phone",
        label: "Telefonnummer",
        type: "text",
      },
      {
        field: "field_age",
        label: "Ålder",
        type: "text",
      },
      {
        field: "field_weight",
        label: "Vikt",
        type: "text",
      },
      {
        field: "field_gender",
        label: "Kön",
        type: "text",
      },
      {
        label: "Faktureringsadress",
        type: "separator",
      },
      {
        field: "field_billing_first_name",
        label: "Förnamn",
        type: "text",
      },
      {
        field: "field_billing_last_name",
        label: "Efternamn",
        type: "text",
      },
      {
        field: "field_billing_address_1",
        label: "Adress 1",
        type: "text",
      },
      {
        field: "field_billing_address_2",
        label: "Adress 2",
        type: "text",
      },
      {
        field: "field_billing_postcode",
        label: "Postnummer",
        type: "text",
      },
      {
        field: "field_billing_city",
        label: "Stad",
        type: "text",
      },
      {
        field: "field_billing_country",
        label: "Land",
        type: "text",
      },
    ],
    searchTags: [],
  },
  notification: {
    listTitle: "Notiser",
    editTitle: "Redigera notis",
    addTitle: "Skapa notis",
    fields: [
      {
        field: "title",
        label: "Titel",
        type: "text",
        required: true,
      },
      { field: "body", label: "Beskrivning", type: "body" },
    ],
    searchTags: [],
  },
  program: {
    listTitle: "Programmeringar",
    editTitle: "Redigera programmering",
    addTitle: "Skapa programmering",
    fields: [
      {
        field: "title",
        label: "Titel",
        type: "text",
        required: true,
      },
      {
        field: "field_status",
        label: "Publiceringsstatus",
        type: "select",
        default: "draft",
        options: [
          { value: "draft", label: "Utkast" },
          { value: "published", label: "Publicerad" },
          { value: "archived", label: "Arkiverad" },
        ],
      },
      {
        field: "field_access",
        label: "Access",
        type: "term",
        vocabulary: "access",
        required: true,
        single: true,
        showSearch: false,
      },
      {
        field: "field_program_tags",
        label: "Taggar",
        type: "term",
        vocabulary: "program_tags",
      },
      {
        field: "field_program_categories",
        label: "Kategorier",
        type: "term",
        vocabulary: "program_categories",
      },
      {
        field: "field_program_goals",
        label: "Mål",
        type: "term",
        vocabulary: "program_goals",
      },
      {
        field: "field_program_tools",
        label: "Redskap",
        type: "term",
        vocabulary: "program_tools",
      },
      { field: "body", label: "Beskrivning", type: "body" },
      { field: "field_media", type: "media", label: "Media" },
      {
        field: "field_program_workouts",
        label: "Träningspass",
        type: "node-reference",
        bundle: "workout",
      },
      {
        field: "field_program_related",
        label: "Relaterade",
        type: "node-reference",
        bundle: "program",
      },
    ],
    searchTags: [
      {
        field: "field_access",
        label: "Access",
        provider: "access",
      },
      {
        field: "field_program_tags",
        label: "Taggar",
        provider: "program_tags",
      },
      {
        field: "field_program_categories",
        label: "Kategorier",
        provider: "program_categories",
      },
    ],
  },
  workout: {
    listTitle: "Träningspass",
    editTitle: "Redigera pass",
    addTitle: "Skapa pass",
    fields: [
      {
        field: "title",
        label: "Titel",
        type: "text",
        required: true,
      },
      {
        field: "field_workout_tags",
        label: "Taggar",
        type: "term",
        vocabulary: "workout_tags",
      },
      {
        field: "field_workout_show_timer",
        label: "Visa timer",
        type: "boolean",
      },
      { field: "body", label: "Beskrivning", type: "body" },
      {
        field: "field_workout_blocks",
        label: "Övningar",
        type: "workout-blocks",
      },
    ],
    searchTags: [
      {
        field: "field_workout_tags",
        label: "Taggar",
        provider: "workout_tags",
      },
    ],
  },
  exercise: {
    listTitle: "Övningar",
    editTitle: "Redigera övning",
    addTitle: "Skapa övning",
    fields: [
      {
        field: "title",
        label: "Administrativ titel",
        type: "text",
        required: true,
      },
      { field: "field_public_title", label: "Publik titel", type: "text" },
      {
        field: "field_exercise_tags",
        label: "Taggar",
        type: "term",
        vocabulary: "exercise_tags",
      },
      {
        field: "field_exercise_tools",
        label: "Redskap",
        type: "term",
        vocabulary: "exercise_tools",
      },
      { field: "body", label: "Beskrivning", type: "body" },
      { field: "field_media", type: "media", label: "Media" },
    ],
    searchTags: [
      {
        field: "field_exercise_tags",
        label: "Taggar",
        provider: "exercise_tags",
      },
      {
        field: "field_exercise_tools",
        label: "Redskap",
        provider: "exercise_tools",
      },
    ],
  },
};
