import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  IonPage,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonGrid,
  IonSpinner,
  IonLabel,
} from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";
import { add } from "ionicons/icons";

import { pageConfig } from "../../settings";
import { buildSearchUrl } from "../../form/helpers";

import AppTagFilter from "../../form/AppTagFilter";

const AdminEntityList = () => {
  const { type } = useParams();
  const providerId = `admin-entity-list-${type}`;
  const { data: entities, isLoading } = useZeusDataProvider(providerId, []);
  const [searchString, setSearchString] = useState("");
  const [searchUrl, setSearchUrl] = useState("");
  const [searchTags, setSearchTags] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const providerRef = useRef();
  const hasMore = providerRef.current?.source?.hasNextPage;

  const doSearch = (e) => {
    e?.preventDefault();
    setSearchString(searchValue);
  };

  const doLoadMore = () => {
    document.dispatchEvent(
      new CustomEvent(`zeusDataOperation:${providerId}`, {
        detail: { name: "next-page" },
      })
    );
  };

  const doResetFilter = () => {
    setSearchString("");
    setSearchValue("");
    setSearchTags({});
  };

  useEffect(() => {
    setSearchUrl(buildSearchUrl(type, searchString, searchTags));
  }, [type, searchString, searchTags]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{pageConfig[type].listTitle}</IonTitle>
          {pageConfig[type].showAdd !== false && (
            <IonButtons slot="primary">
              <IonButton routerLink={`/c/admin/${type}/add`}>
                Skapa
                <IonIcon icon={add} slot="end" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol>
              <form onSubmit={doSearch} action="/">
                <IonSearchbar
                  placeholder="Sök"
                  inputmode="search"
                  value={searchValue}
                  debounce={0}
                  onIonChange={(e) => setSearchValue(e.target.value)}
                />
              </form>
            </IonCol>

            {pageConfig[type].searchTags.map((tag) => (
              <IonCol key={tag.field}>
                <AppTagFilter
                  label={tag.label}
                  provider={tag.provider}
                  value={searchTags[tag.field] ?? ""}
                  onChange={(value) =>
                    setSearchTags((current) => ({
                      ...current,
                      [tag.field]: value,
                    }))
                  }
                />
              </IonCol>
            ))}

            <IonCol size="auto">
              <IonButton onClick={doResetFilter} fill="outline">
                Återställ
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonList>
          {entities.map((item) => (
            <IonItem key={item.id} routerLink={`/c/admin/${type}/${item.id}`}>
              <IonLabel>{item.title}</IonLabel>
            </IonItem>
          ))}
        </IonList>
        {isLoading && (
          <div className="ion-text-center">
            <IonSpinner />
          </div>
        )}
        {hasMore && (
          <div className="ion-text-center">
            <IonButton onClick={doLoadMore}>Ladda fler</IonButton>
          </div>
        )}
      </IonContent>
      <zeus-data-provider id={providerId} listen-tags={type} ref={providerRef}>
        <zeus-data-source-jsonapi url={searchUrl}></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminEntityList;
