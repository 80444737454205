import {
  IonButton,
  IonItem,
  IonIcon,
  IonReorderGroup,
  IonReorder,
  IonLabel,
  IonItemDivider,
} from "@ionic/react";
import { trashOutline } from "ionicons/icons";

const AppNodeSelector = ({ label, value = [], onChange = (e) => {} }) => {
  const doReorder = (event) => {
    const newValue = event.detail.complete(value);
    onChange(
      new CustomEvent("nodeSelectorChange", { detail: { value: newValue } })
    );
  };

  const doRemove = (index) => {
    // Copy value and remove item.
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(
      new CustomEvent("nodeSelectorChange", { detail: { value: newValue } })
    );
  };

  return (
    <>
      <IonItemDivider>
        <IonLabel>{label}</IonLabel>
      </IonItemDivider>
      <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
        {value.map((item, index) => (
          <IonItem key={index}>
            <IonReorder slot="start" />
            <IonLabel>{item.title}</IonLabel>
            <IonButton slot="end" fill="clear" onClick={() => doRemove(index)}>
              <IonIcon slot="icon-only" icon={trashOutline} />
            </IonButton>
          </IonItem>
        ))}
      </IonReorderGroup>
    </>
  );
};

export default AppNodeSelector;
