import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting, pageConfig } from "../settings";
import { generateAttributeString } from "../form/helpers";

export function useProfileProvider(id) {
  const type = "profile_client";
  const providerId = `node-${type}-provider-${id}`;
  const params = {
    include: "field_user,field_subscriptions,field_profile_client_access",
  };

  const apiRoot = `${getSetting("BACKEND")}/api/v4`;
  const url = `${getSetting(
    "BACKEND"
  )}/api/profile?id=${id}&${new URLSearchParams(params)}`;

  const { data, isLoading, setData, error } = useZeusDataProvider(providerId);

  const Provider = useMemo(() => {
    const Component = () => {
      if (!id) {
        return null;
      }
      return (
        <zeus-data-provider
          id={providerId}
          send-tags={type}
          listen-tags={`profile_client-${id}`}
        >
          <zeus-data-source-jsonapi
            url={url}
            type={`node--${type}`}
            data-action="update"
            attributes={
              generateAttributeString(pageConfig.profile_client.fields) +
              ",field_subscriptions"
            }
            relationships="field_subscriptions:paragraph--subscription_manual"
            api-root={apiRoot}
            include-type
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [id, providerId, type, url, apiRoot]);

  return {
    Provider,
    providerId,
    entity: data,
    isLoading,
    error,
    setData,
  };
}
