import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  IonButton,
  IonList,
  IonLoading,
  useIonRouter,
  IonPage,
  IonAlert,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  useIonViewWillLeave,
  IonIcon,
} from "@ionic/react";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { getSetting, pageConfig } from "../../settings";
import { useError } from "../../hooks";
import { useFormState } from "../../form/hooks";
import { FormItem } from "../../form/FormItem";
import {
  generateRelationshipsString,
  generateAttributeString,
  generateIncludeString,
} from "../../form/helpers";
import { checkmarkOutline } from "ionicons/icons";

const AdminEntityCopy = () => {
  const { id, type } = useParams();
  const providerId = `entity-${id}`;
  const copyProviderId = `entity-${id}-copy`;
  const router = useIonRouter();
  const {
    data: entity,
    isLoading,
    error: readError,
  } = useZeusDataProvider(providerId);
  const {
    setData,
    error: writeError,
    isLoading: isSaving,
  } = useZeusDataProvider(copyProviderId);

  const [formState, updateFormState, validateFormState, onLeave] = useFormState(
    pageConfig[type].fields
  );

  const setValidationError = useError();

  useZeusDataStatusSubscriber(copyProviderId, (status) => {
    if (status === "created") {
      router.push(`/c/admin/${type}/${id}`, "back");
    }
  });

  useEffect(() => {
    if (entity) {
      pageConfig[type].fields.forEach((field) => {
        updateFormState(field, entity[field.field]);
      });
    }
  }, [entity, type, updateFormState]);

  const doSave = () => {
    const errors = validateFormState();
    if (errors.length) {
      setValidationError(errors[0]);
      return;
    }

    const _paragraphs = [];
    if (formState.field_workout_blocks) {
      _paragraphs.push({
        field: "field_workout_blocks",
        type: "paragraph--workout_block",
        attributes: [
          "field_title",
          "field_workout_block_exercises",
          "field_description",
        ],
        relationships: {
          field_workout_block_exercises: {
            type: "node--exercise",
            ref: "id",
          },
        },
        values: formState.field_workout_blocks.map((value) => ({
          ...value,
          id: "new__1",
          action: "create",
        })),
      });
    }

    setData({ ...formState, _paragraphs });
  };

  useIonViewWillLeave(() => {
    onLeave();
  }, [onLeave]);

  return (
    <IonPage>
      <IonLoading isOpen={isLoading || isSaving} />
      <IonAlert
        isOpen={readError !== "" || writeError !== ""}
        message={readError + writeError}
        header="Mized"
        buttons={["Ok"]}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/c/admin/${type}`} />
          </IonButtons>
          <IonTitle>{pageConfig[type].copyTitle}</IonTitle>
          <IonTitle>Kopiera {entity?.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={doSave}>
              Spara
              <IonIcon icon={checkmarkOutline} slot="end" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList class="form">
          {pageConfig[type].fields.map((field) => (
            <FormItem
              key={field.field}
              label={field.label}
              type={field.type}
              config={field}
              value={formState[field.field]}
              onChange={(e) => updateFormState(field, e.detail.value)}
            />
          ))}
        </IonList>
      </IonContent>

      <zeus-data-provider id={copyProviderId} send-tags={type} write-only>
        <zeus-data-source-jsonapi
          type={`node--${type}`}
          attributes={generateAttributeString(pageConfig[type].fields)}
          relationships={generateRelationshipsString(pageConfig[type].fields)}
          url={`${getSetting("BACKEND")}/api/v4/node/${type}`}
          data-action="create"
          api-root={`${getSetting("BACKEND")}/api/v4`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider id={providerId}>
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/api/v4/node/${type}/${id}?include=${generateIncludeString(
            pageConfig[type].fields
          )}`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminEntityCopy;
