import { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
  IonList,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";

import { FormItem } from "./FormItem";
import { closeOutline } from "ionicons/icons";

const MultipleField = ({ field, lines, label, onChange, value = [] }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const doEmitChange = (index, newValue) => {
    const newValues = [...value];
    newValues[index] = newValue;
    onChange(
      new CustomEvent("tagSelectorChange", {
        detail: {
          value: newValues,
        },
      })
    );
  };
  return (
    <>
      <IonModal isOpen={modalOpen} onDidDismiss={() => setModalOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{label}</IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={() => setModalOpen(false)}>
                Stäng
                <IonIcon slot="end" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList class="form">
            {value.map((innerValue, i) => (
              <FormItem
                lines={lines}
                key={i}
                type={field.subType}
                config={field}
                value={innerValue}
                onChange={(e) => {
                  doEmitChange(i, e.detail.value);
                }}
              />
            ))}
          </IonList>
        </IonContent>
      </IonModal>
      <IonItem onClick={() => setModalOpen(true)}>
        <IonLabel>{label}</IonLabel>
      </IonItem>
    </>
  );
};

export default MultipleField;
