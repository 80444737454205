import { useEffect } from "react";
import { IonLoading } from "@ionic/react";
import {
  useDynamicDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

const EntityStatusUpdateAction = ({
  type,
  bundle,
  id,
  status,
  onDone = () => {},
}) => {
  const [actionResponse] = useDynamicDataProvider("action-provider");
  const data = { id, status };
  useEffect(() => {
    if (actionResponse?.status === status) {
      onDone();
    }
  }, [actionResponse, onDone, status]);
  return (
    <>
      <IonLoading
        isOpen={true}
        showBackdrop={false}
        message="Uppdaterar status"
      />
      <zeus-data-provider
        id="action-provider"
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags={bundle}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/jsonapi/${type}/${bundle}/${id}`}
          attributes="status"
          type={`${type}--${bundle}`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const EntityDeleteAction = ({ type, bundle, id, onDone = () => {} }) => {
  const data = { id };
  useZeusDataStatusSubscriber("action-provider", (status) => {
    if (status === "deleted") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Raderar" />
      <zeus-data-provider
        id="action-provider"
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags={bundle}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/api/v4/${type}/${bundle}/${id}`}
          attributes="status"
          type={`${type}--${bundle}`}
          data-action="delete"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const ActionModal = ({ action, type, id, onDone }) => {
  const entityType = type === "event" ? "group" : "node";
  if (action === "publish") {
    return (
      <EntityStatusUpdateAction
        type={entityType}
        bundle={type}
        id={id}
        status={true}
        onDone={onDone}
      />
    );
  } else if (action === "unpublish") {
    return (
      <EntityStatusUpdateAction
        type={entityType}
        bundle={type}
        id={id}
        status={false}
        onDone={onDone}
      />
    );
  } else if (action === "delete") {
    return (
      <EntityDeleteAction
        type={entityType}
        bundle={type}
        id={id}
        onDone={onDone}
      />
    );
  }

  return null;
};

export default ActionModal;
