import { useParams } from "react-router-dom";
import {
  IonList,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonItemDivider,
  IonItem,
  IonLabel,
  IonLoading,
  IonInput,
} from "@ionic/react";
import dayjs from "dayjs";

import { useSubinfoProvider } from "../../providers/subinfo-provider";

function formatDateTime(input) {
  if (!input) {
    return "";
  }
  return dayjs(input).format("YYYY-MM-DD HH:mm");
}

const AdminSubscription = () => {
  const { id } = useParams();
  const { Provider, entity, isLoading } = useSubinfoProvider(id);

  return (
    <IonPage>
      <Provider />
      <IonLoading isOpen={isLoading} />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/c/admin/users`} />
          </IonButtons>
          <IonTitle>Abonnemang</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList class="form">
          <IonItem>
            <IonInput
              label="Titel"
              labelPlacement="stacked"
              type="text"
              readonly={true}
              value={entity?.title}
            />
          </IonItem>
          <IonItem>
            <IonInput
              label="Skapat"
              labelPlacement="stacked"
              type="text"
              readonly={true}
              value={formatDateTime(entity?.created)}
            />
          </IonItem>
          <IonItem>
            <IonInput
              label="Förnyas/Giltigt till"
              labelPlacement="stacked"
              type="text"
              readonly={true}
              value={formatDateTime(entity?.next_renewal)}
            />
          </IonItem>
          <IonItem>
            <IonInput
              label="Status"
              labelPlacement="stacked"
              type="text"
              readonly={true}
              value={entity?.state}
            />
          </IonItem>

          <IonItemDivider color="light">Order</IonItemDivider>
          {entity?.orders?.map((order) => (
            <IonItem key={order.order_number}>
              <IonLabel>
                <h2>Order {order.order_number}</h2>
                <p>
                  {formatDateTime(order.date)} - {order.total}
                </p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default AdminSubscription;
