import {
  IonList,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  useIonModal,
  IonLoading,
} from "@ionic/react";
import { closeOutline, checkmarkOutline } from "ionicons/icons";
import { useRef } from "react";
import { useAlert } from "../hooks";
import { useUserProvider } from "../providers/user-provider";

export function useUpdateEmailModal(userId, profileId) {
  const handleDissmiss = () => {
    dismiss();
  };
  const [present, dismiss] = useIonModal(UpdateEmailModal, {
    userId,
    profileId,
    onDone: handleDissmiss,
  });
  return present;
}

const UpdateEmailModal = ({ userId, profileId, onDone }) => {
  const mailInput = useRef();
  const { Provider, setData, isLoading } = useUserProvider(userId, profileId);
  const showAlert = useAlert();

  const doSubmit = () => {
    setData({
      id: userId,
      mail: mailInput.current.value,
      name: mailInput.current.value,
    });
  };

  const onStatusChange = (status) => {
    if (status === "error") {
      showAlert(
        "Kunde inte uppdatera e-postadressen. Kontrollera att du angett en giltig e-postadress och att den inte redan är använd på ett annat konto."
      );
    }

    if (status === "updated") {
      onDone();
    }
  };

  return (
    <>
      <Provider onStatusChange={onStatusChange} />
      <IonLoading isOpen={isLoading} />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="secondary">
            <IonButton onClick={onDone}>
              Stäng
              <IonIcon icon={closeOutline} slot="end" />
            </IonButton>
          </IonButtons>
          <IonTitle>Byt e-postadress</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={doSubmit}>
              Spara <IonIcon slot="end" icon={checkmarkOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel class="ion-text-wrap">
              <h2>
                Fyll i fältet nedan för att byta e-postadress för användaren.
              </h2>
              <p>
                Användaren kommer bli utloggad från appen och behöver logga in
                igen med den nya e-postadressen.
              </p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonInput
              label="Ny e-postadress"
              labelPlacement="stacked"
              ref={mailInput}
              type="email"
              placeholder="name@example.com"
            />
          </IonItem>
        </IonList>
      </IonContent>
    </>
  );
};

export default UpdateEmailModal;
