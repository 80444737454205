function videoUrlParser(url) {
  if (!url) {
    return false;
  }

  const match = url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  );

  if (!match || !match[6]) {
    return false;
  }

  if (url.indexOf("you") !== -1) {
    return {
      type: "youtube",
      id: match[6],
    };
  }

  return {
    type: "vimeo",
    id: match[6],
  };
}

const EmbededVideo = ({ src }) => {
  const video = videoUrlParser(src);

  if (video?.type === "youtube") {
    return (
      <iframe
        src={`https://www.youtube.com/embed/${video.id}?mute=1`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  }
  if (video?.type === "vimeo") {
    return (
      <iframe
        title="Vimeo video player"
        src={`https://player.vimeo.com/video/${video.id}?muted=1`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    );
  }

  return null;
};

export default EmbededVideo;
