import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useSubinfoProvider(id) {
  const providerId = `subinfo-provider-${id}`;
  const params = { id };
  const apiRoot = `${getSetting("BACKEND")}/api`;
  const url = `${apiRoot}/subinfo?${new URLSearchParams(params)}`;
  const { data, isLoading } = useZeusDataProvider(providerId);
  const Provider = useMemo(() => {
    const Component = () => {
      if (!id) {
        return null;
      }
      return (
        <zeus-data-provider id={providerId}>
          <zeus-data-source-json url={url}></zeus-data-source-json>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [id, providerId, url]);
  return {
    Provider,
    providerId,
    entity: data,
    isLoading,
  };
}
