import {
  IonItem,
  IonLabel,
  IonInput,
  IonToggle,
  IonSelect,
  IonSelectOption,
  IonItemDivider,
  IonTextarea,
} from "@ionic/react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import AppTagSelector from "./AppTagSelector";
import AppMediaSelector from "./AppMediaSelector";
import AppNodeSelector from "./AppNodeSelector";
import AppBlockSelector from "./AppBlockSelector";
import MultipleField from "./MultipleField";

export const FormItem = ({ value, onChange, label, type, config, lines }) => {
  if (type === "multiple") {
    return (
      <MultipleField
        field={config}
        lines={lines}
        label={label}
        onChange={onChange}
        value={value}
      />
    );
  }

  if (type === "separator") {
    return <IonItemDivider color="light">{label}</IonItemDivider>;
  }

  if (type === "text") {
    return (
      <IonItem lines={lines}>
        <IonInput
          label={label}
          labelPlacement="stacked"
          value={value}
          onIonChange={onChange}
        />
      </IonItem>
    );
  }

  if (type === "value") {
    return (
      <IonItem lines={lines}>
        <IonInput
          label={label}
          labelPlacement="stacked"
          value={config.value}
          readonly
        />
      </IonItem>
    );
  }

  if (type === "link") {
    return (
      <>
        {config.showTitle && (
          <IonItem lines={lines}>
            <IonInput
              label={`${label} (title)`}
              labelPlacement="stacked"
              value={value.title}
              onIonChange={(e) => {
                onChange(
                  new CustomEvent("tagSelectorChange", {
                    detail: {
                      value: { title: e.detail.value, uri: value.uri },
                    },
                  })
                );
              }}
            />
          </IonItem>
        )}
        <IonItem lines={lines}>
          <IonInput
            label={`${label} (uri)`}
            labelPlacement="stacked"
            value={value.uri}
            onIonChange={(e) => {
              onChange(
                new CustomEvent("tagSelectorChange", {
                  detail: {
                    value: { uri: e.detail.value, title: value.title },
                  },
                })
              );
            }}
          />
        </IonItem>
      </>
    );
  }

  if (type === "longtext") {
    return (
      <IonItem lines={lines}>
        <IonLabel position="stacked">{label}</IonLabel>
        <IonTextarea value={value} onIonChange={onChange} autoGrow={true} />
      </IonItem>
    );
  }

  if (type === "boolean") {
    return (
      <IonItem lines={lines}>
        <IonToggle
          checked={value}
          onIonChange={(e) => {
            onChange(
              new CustomEvent("tagSelectorChange", {
                detail: { value: e.detail.checked },
              })
            );
          }}
        >
          {label}
        </IonToggle>
      </IonItem>
    );
  }

  if (type === "select") {
    return (
      <IonItem lines={lines}>
        <IonSelect value={value} onIonChange={onChange} label={label}>
          {config.options.map((option) => (
            <IonSelectOption value={option.value} key={option.value}>
              {option.label}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    );
  }

  if (type === "body") {
    return (
      <IonItem lines={lines}>
        <IonLabel position="stacked">{label}</IonLabel>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "16px",
            width: "100%",
          }}
        >
          <CKEditor
            editor={Editor}
            data={value.value}
            onChange={(_, editor) => {
              onChange(
                new CustomEvent("tagSelectorChange", {
                  detail: {
                    value: { value: editor.getData(), format: "wysiwyg" },
                  },
                })
              );
            }}
          />
        </div>
      </IonItem>
    );
  }
  if (type === "term") {
    return (
      <AppTagSelector
        lines={lines}
        title={label}
        vocabulary={config.vocabulary}
        value={value}
        onChange={onChange}
        single={config.single}
        showSearch={config.showSearch}
      />
    );
  }
  if (type === "media") {
    return <AppMediaSelector label={label} onChange={onChange} value={value} />;
  }
  if (type === "node-reference") {
    return <AppNodeSelector label={label} value={value} onChange={onChange} />;
  }
  if (type === "workout-blocks") {
    return <AppBlockSelector label={label} value={value} onChange={onChange} />;
  }

  return null;
};
