import { useEffect, useCallback, useState } from "react";
import { useIonAlert } from "@ionic/react";

import { useConnector } from "../connector-context";
import { getSetting } from "../settings";

function getInitialValue(field) {
  const type = field.type;
  if (type === "multiple") {
    return [];
  }
  if (type === "select") {
    return field.default;
  }
  if (type === "term") {
    return [];
  }
  if (type === "media") {
    return null;
  }
  if (type === "node-reference") {
    return [];
  }
  if (type === "workout-blocks") {
    return [];
  }
  if (type === "boolean") {
    return false;
  }
  if (type === "body") {
    return { value: "", format: "wysiwyg" };
  }
  if (type === "link") {
    return { title: "", uri: "" };
  }

  return "";
}

function getValue(field, value) {
  const type = field.type;

  if (type === "multiple") {
    return Array.isArray(value)
      ? value.map((i) => getValue({ type: field.subType }, i))
      : [];
  }
  if (type === "boolean") {
    return value === true;
  }
  if (type === "body") {
    return {
      value: value?.value ?? "",
      format: value?.format ?? "wysiwyg",
    };
  }
  if (
    type === "term" ||
    type === "node-reference" ||
    type === "workout-blocks"
  ) {
    if (Array.isArray(value)) {
      return value;
    } else if (value !== null && value !== undefined) {
      return [value];
    }
    return [];
  }

  return value;
}

function isEmpty(type, value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (type === "body") {
    return !Boolean(value?.value);
  }
  return !Boolean(value);
}

export function useFormState(fields, entity, readOnly = false) {
  const initial = {};
  fields.forEach((field) => {
    initial[field.field] = getInitialValue(field);
  });

  const [formState, setFormState] = useState(initial);

  const { setConnectorIsOpen, setConnectorTargets, setConnectorCallback } =
    useConnector();

  const updateFormState = useCallback((field, value) => {
    setFormState((current) => {
      return {
        ...current,
        [field.field]: getValue(field, value),
      };
    });
  }, []);

  const validateFormState = useCallback(() => {
    const errors = [];
    fields.forEach((field) => {
      if (field.required && isEmpty(field.type, formState[field.field])) {
        errors.push(`Fältet "${field.label}" är obligatoriskt.`);
      }
    });
    return errors;
  }, [fields, formState]);

  const onLeave = useCallback(() => {
    if (readOnly) {
      return;
    }
    setConnectorIsOpen(false);
    setConnectorTargets([]);
    setConnectorCallback({ cb: null });
  }, [setConnectorCallback, setConnectorIsOpen, setConnectorTargets, readOnly]);

  useEffect(() => {
    if (readOnly) {
      return;
    }
    const refs = fields.filter(
      (i) => i.type === "node-reference" || i.type === "workout-blocks"
    );
    setConnectorIsOpen(refs.length > 0);
    setConnectorTargets(refs);
    setConnectorCallback({
      cb: ({ field, entity }) => {
        if (field.type === "node-reference") {
          const current = formState[field.field];
          updateFormState(field, [...current, entity]);
        } else if (
          field.type === "workout-blocks" &&
          entity.field_workout_block_exercises !== undefined
        ) {
          const newValue = [...formState[field.field], entity];
          updateFormState(field, newValue);
        } else if (field.type === "workout-blocks") {
          const newValue = [...formState[field.field]];
          if (newValue.length === 0) {
            newValue.push({
              id: `new__${Math.random()}`,
              field_workout_block_exercises: [entity],
            });
          } else {
            newValue[newValue.length - 1].field_workout_block_exercises.push(
              entity
            );
          }
          updateFormState(field, newValue);
        }
      },
    });
  }, [
    formState,
    setConnectorCallback,
    setConnectorIsOpen,
    setConnectorTargets,
    updateFormState,
    fields,
    readOnly,
  ]);

  useEffect(() => {
    if (entity) {
      fields.forEach((field) => {
        updateFormState(field, entity[field.field]);
      });
    }
  }, [entity, fields, updateFormState]);

  return [formState, updateFormState, validateFormState, onLeave];
}

export function useDialogs() {
  const [presentAlert] = useIonAlert();
  const confirmDangerousAction = useCallback(
    ({ message, button, handler }) => {
      presentAlert({
        header: getSetting("APP_NAME"),
        message,
        buttons: [
          { text: "Avbryt", role: "cancel" },
          {
            text: button,
            role: "destructive",
            handler,
          },
        ],
      });
    },
    [presentAlert]
  );
  return { confirmDangerousAction };
}
