import { useParams } from "react-router-dom";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
  useIonViewWillLeave,
} from "@ionic/react";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { getSetting, pageConfig } from "../../settings";
import { useError } from "../../hooks";
import { useFormState } from "../../form/hooks";
import { FormItem } from "../../form/FormItem";
import {
  generateRelationshipsString,
  generateAttributeString,
} from "../../form/helpers";
import { checkmarkOutline } from "ionicons/icons";

const AdminEntityAdd = () => {
  const { type } = useParams();
  const providerId = `entity-create-${type}`;
  const router = useIonRouter();
  const setValidationError = useError();
  const { setData, isLoading, error } = useZeusDataProvider(providerId);
  const [formState, updateFormState, validateFormState, onLeave] = useFormState(
    pageConfig[type].fields
  );

  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "created") {
      router.push(`/c/admin/${type}`, "back");
    }
  });

  const doSave = () => {
    const errors = validateFormState();
    if (errors.length) {
      setValidationError(errors[0]);
      return;
    }

    const _paragraphs = [];
    if (formState.field_workout_blocks) {
      _paragraphs.push({
        field: "field_workout_blocks",
        type: "paragraph--workout_block",
        attributes: [
          "field_title",
          "field_workout_block_exercises",
          "field_description",
        ],
        relationships: {
          field_workout_block_exercises: {
            type: "node--exercise",
            ref: "id",
          },
        },
        values: formState.field_workout_blocks.map((value) => ({
          ...value,
          action: value.id.startsWith("new__") ? "create" : "update",
        })),
      });
    }

    setData({ ...formState, _paragraphs });
  };

  useIonViewWillLeave(() => {
    onLeave();
  }, [onLeave]);

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <IonAlert
        isOpen={error !== ""}
        message={error}
        header="Mized"
        buttons={["Ok"]}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/c/admin/${type}`} />
          </IonButtons>
          <IonTitle>{pageConfig[type].addTitle}</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={doSave}>
              Spara
              <IonIcon icon={checkmarkOutline} slot="end" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList class="form">
          {pageConfig[type].fields.map((field) => (
            <FormItem
              key={field.field}
              label={field.label}
              type={field.type}
              config={field}
              value={formState[field.field]}
              onChange={(e) => updateFormState(field, e.detail.value)}
            />
          ))}
        </IonList>
        <zeus-data-provider id={providerId} send-tags={type} write-only>
          <zeus-data-source-jsonapi
            url={`${getSetting("BACKEND")}/api/v4/node/${type}`}
            type={`node--${type}`}
            api-root={`${getSetting("BACKEND")}/api/v4`}
            attributes={generateAttributeString(pageConfig[type].fields)}
            relationships={generateRelationshipsString(pageConfig[type].fields)}
            data-action="create"
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      </IonContent>
    </IonPage>
  );
};

export default AdminEntityAdd;
