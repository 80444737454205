import { useState } from "react";
import {
  IonAlert,
  IonContent,
  IonList,
  IonLoading,
  IonPage,
  IonItem,
  IonInput,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
} from "@ionic/react";
import { keyOutline, mailOutline } from "ionicons/icons";
import { useDynamicDataAuthentication } from "@understandit/dynamic-data-react";
import CenterCenter from "../components/CenterCenter";

export const Login = () => {
  const [formValues, setFormValues] = useState({
    username: "",
    password: "",
  });

  const { error, isLoading, login, reset } =
    useDynamicDataAuthentication("auth");

  const handleLogin = (e) => {
    e?.preventDefault();
    login(formValues.username, formValues.password);
  };

  const resetLogin = () => {
    reset();
    setFormValues({ ...formValues, password: "" });
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={error !== ""}
        buttons={["Ok"]}
        onDidDismiss={resetLogin}
        header="Lofsan"
        message={error}
      />

      <IonLoading isOpen={isLoading} />
      <IonHeader>
        <IonToolbar>
          <IonTitle>Logga in</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <CenterCenter>
          <div style={{ maxWidth: "500px", width: "100%" }}>
            <form action="/" onSubmit={handleLogin}>
              <input type="submit" className="ion-hide" />
              <IonList className="ion-padding">
                <IonItem>
                  <IonIcon icon={mailOutline} slot="start" />
                  <IonInput
                    value={formValues.username}
                    onIonChange={(e) =>
                      setFormValues({
                        ...formValues,
                        username: e.target.value,
                      })
                    }
                    type="email"
                    autocomplete="username"
                    placeholder="E-post"
                  />
                </IonItem>
                <IonItem>
                  <IonIcon icon={keyOutline} slot="start" />
                  <IonInput
                    value={formValues.password}
                    onIonChange={(e) =>
                      setFormValues({
                        ...formValues,
                        password: e.target.value,
                      })
                    }
                    type="password"
                    autocomplete="password"
                    placeholder="Lösenord"
                  />
                </IonItem>
              </IonList>
              <div className="ion-padding-horizontal">
                <IonButton expand="block" type="submit">
                  Logga in
                </IonButton>
              </div>
            </form>
          </div>
        </CenterCenter>
      </IonContent>
    </IonPage>
  );
};

export default Login;
