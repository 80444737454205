import { createContext, useState, useContext } from "react";

const ConnectorContext = createContext();

export function ConnectorProvider({ children }) {
  const [connectorIsOpen, setConnectorIsOpen] = useState(false);
  const [connectorTargets, setConnectorTargets] = useState([]);
  const [connectorCallback, setConnectorCallback] = useState({ cb: null });

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {
    connectorIsOpen,
    setConnectorIsOpen,
    connectorTargets,
    setConnectorTargets,
    connectorCallback,
    setConnectorCallback,
  };
  return (
    <ConnectorContext.Provider value={value}>
      {children}
    </ConnectorContext.Provider>
  );
}

export function useConnector() {
  const context = useContext(ConnectorContext);
  if (context === undefined) {
    throw new Error("useConnector must be used within a ConnectorProvider");
  }
  return context;
}
