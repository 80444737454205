import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useUsersProvider(searchString, searchTags) {
  const providerId = `node-profile_client-provider`;
  const params = {
    include: "field_user,field_profile_client_access",
    sort: "-created",
  };

  if (searchString) {
    params["filter[en-group][group][conjunction]"] = "OR";

    params["filter[name-filter][condition][path]"] = "field_name";
    params["filter[name-filter][condition][value]"] = searchString;
    params["filter[name-filter][condition][operator]"] = "CONTAINS";
    params["filter[name-filter][condition][memberOf]"] = "en-group";

    params["filter[mail-filter][condition][path]"] = "field_user.mail";
    params["filter[mail-filter][condition][value]"] = searchString;
    params["filter[mail-filter][condition][operator]"] = "CONTAINS";
    params["filter[mail-filter][condition][memberOf]"] = "en-group";
  }

  if (searchTags?.field_profile_client_access) {
    params["filter[access][condition][path]"] =
      "field_profile_client_access.id";
    params["filter[access][condition][value]"] =
      searchTags.field_profile_client_access;
  }

  const apiRoot = `${getSetting("BACKEND")}/api/v4`;
  const url = `${apiRoot}/node/profile_client?${new URLSearchParams(params)}`;

  const { data, isLoading, setData } = useZeusDataProvider(providerId, []);

  const Provider = useMemo(() => {
    const Component = () => {
      return (
        <zeus-data-provider id={providerId} listen-tags="profile_client">
          <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [providerId, url]);

  return {
    Provider,
    providerId,
    users: data,
    isLoading,
    setData,
  };
}
