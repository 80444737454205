import { useParams } from "react-router-dom";
import { checkmarkOutline, copyOutline, trashOutline } from "ionicons/icons";
import {
  IonButton,
  IonIcon,
  IonList,
  IonLoading,
  useIonRouter,
  IonPage,
  IonAlert,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  useIonViewWillLeave,
} from "@ionic/react";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { getSetting, pageConfig } from "../../settings";
import { useError, useEntityAction } from "../../hooks";
import { useDialogs, useFormState } from "../../form/hooks";
import { FormItem } from "../../form/FormItem";
import {
  generateRelationshipsString,
  generateAttributeString,
  generateIncludeString,
} from "../../form/helpers";

const AdminEntityEdit = () => {
  const { id, type } = useParams();
  const providerId = `entity-edit-${type}-${id}`;
  const router = useIonRouter();
  const {
    data: entity,
    isLoading,
    setData,
    error,
  } = useZeusDataProvider(providerId);
  const [formState, updateFormState, validateFormState, onLeave] = useFormState(
    pageConfig[type].fields,
    entity
  );

  const fields = [...pageConfig[type].fields];

  if (type === "program") {
    fields.splice(1, 0, {
      label: "Länk för delning",
      type: "value",
      value: `https://mized.se/dela/${entity?.id}`,
    });
  }

  const setValidationError = useError();
  const { confirmDangerousAction } = useDialogs();

  const doEntityAction = useEntityAction(() => {
    router.push(`/c/admin/${type}`, "back");
  });

  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "updated") {
      router.push(`/c/admin/${type}`, "back");
    }
  });

  const doSave = () => {
    const errors = validateFormState();
    if (errors.length) {
      setValidationError(errors[0]);
      return;
    }

    const _paragraphs = [];
    if (formState.field_workout_blocks) {
      _paragraphs.push({
        field: "field_workout_blocks",
        type: "paragraph--workout_block",
        attributes: [
          "field_title",
          "field_workout_block_exercises",
          "field_description",
        ],
        relationships: {
          field_workout_block_exercises: {
            type: "node--exercise",
            ref: "id",
          },
        },
        values: formState.field_workout_blocks.map((value) => ({
          ...value,
          action: value.id.startsWith("new__") ? "create" : "update",
        })),
      });
    }

    setData({ id, ...formState, _paragraphs });
  };

  const doDelete = () => {
    confirmDangerousAction({
      button: "Ta bort",
      message: "Är du säker på att du vill ta bort innehållet?",
      handler: () => doEntityAction("delete", type, id),
    });
  };

  const doCopy = () => {
    router.push(`/c/admin/${type}/${id}/copy`);
  };

  useIonViewWillLeave(() => {
    onLeave();
  }, [onLeave]);

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <IonAlert
        isOpen={error !== ""}
        message={error}
        header="Mized"
        buttons={["Ok"]}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/c/admin/${type}`} />
          </IonButtons>
          <IonTitle>Redigera {entity?.title}</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={doSave}>
              Spara <IonIcon slot="end" icon={checkmarkOutline} />
            </IonButton>
            <IonButton onClick={doDelete}>
              <IonIcon slot="icon-only" icon={trashOutline} />
            </IonButton>
            <IonButton onClick={doCopy}>
              <IonIcon slot="icon-only" icon={copyOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList class="form">
          {fields.map((field, i) => (
            <FormItem
              key={i}
              label={field.label}
              type={field.type}
              config={field}
              value={formState[field.field]}
              onChange={(e) => updateFormState(field, e.detail.value)}
            />
          ))}
        </IonList>
      </IonContent>
      <zeus-data-provider id={providerId} send-tags={type}>
        <zeus-data-source-jsonapi
          type={`node--${type}`}
          attributes={generateAttributeString(pageConfig[type].fields)}
          relationships={generateRelationshipsString(pageConfig[type].fields)}
          api-root={`${getSetting("BACKEND")}/api/v4`}
          url={`${getSetting(
            "BACKEND"
          )}/api/v4/node/${type}/${id}?include=${generateIncludeString(
            pageConfig[type].fields
          )}`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminEntityEdit;
