import { IonItem, IonSelect, IonSelectOption } from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

const AppTagFilter = ({ provider, onChange, label, value, color, lines }) => {
  const { data: tags } = useZeusDataProvider(provider, []);
  return (
    <IonItem color={color} lines={lines}>
      <IonSelect
        label={label}
        interface="popover"
        value={value}
        onIonChange={(e) => onChange(e.detail.value)}
      >
        <IonSelectOption value="">Alla</IonSelectOption>
        {tags?.map((tag) => (
          <IonSelectOption key={tag.id} value={tag.id}>
            {tag.name}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};

export default AppTagFilter;
