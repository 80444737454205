import {
  IonButton,
  IonItem,
  IonIcon,
  IonLabel,
  IonReorderGroup,
  IonReorder,
  IonModal,
  IonItemDivider,
} from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import { useState } from "react";
import PreviewModal from "../modals/PreviewModal";

const AppBlockSelector = ({ label, value = [], onChange = (e) => {} }) => {
  const exercises = value.reduce(
    (acc, current) => [...acc, ...current.field_workout_block_exercises],
    []
  );

  const [previewId, setPreviewId] = useState("");

  const doReorder = (event) => {
    const sortedExercises = event.detail.complete(exercises);

    // Build new Block structure.
    const newValue = [
      {
        id: value[0]?.id ?? "new__1",
        field_title: "Block",
        field_workout_block_exercises: sortedExercises,
      },
    ];

    onChange(
      new CustomEvent("AppBlockSelectorChange", { detail: { value: newValue } })
    );
  };

  const doRemove = (e, index) => {
    e?.preventDefault();
    e?.stopPropagation();
    const newExerciseList = [...exercises];
    newExerciseList.splice(index, 1);
    const newValue = [
      {
        id: value[0]?.id ?? "new__1",
        field_title: "Block",
        field_workout_block_exercises: newExerciseList,
      },
    ];
    onChange(
      new CustomEvent("AppBlockSelectorChange", { detail: { value: newValue } })
    );
  };

  return (
    <>
      <IonModal isOpen={previewId !== ""}>
        <PreviewModal
          id={previewId}
          type="exercise"
          onDone={() => setPreviewId("")}
        />
      </IonModal>
      <IonItemDivider>
        <IonLabel>{label}</IonLabel>
      </IonItemDivider>
      <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
        {exercises.map((item, index) => (
          <IonItem key={index} onClick={() => setPreviewId(item.id)} button>
            <IonReorder slot="start" />
            <IonLabel>{item.title}</IonLabel>
            <IonButton
              slot="end"
              fill="clear"
              onClick={(e) => doRemove(e, index)}
            >
              <IonIcon slot="icon-only" icon={trashOutline} />
            </IonButton>
          </IonItem>
        ))}
      </IonReorderGroup>
    </>
  );
};

export default AppBlockSelector;
