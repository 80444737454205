import { useMemo } from "react";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useUserProvider(id, profileId) {
  const providerId = `user-${id}`;
  const { data, isLoading, setData, error } = useZeusDataProvider(providerId);
  const Provider = useMemo(() => {
    const Component = ({ onStatusChange }) => {
      useZeusDataStatusSubscriber(providerId, (s) => onStatusChange?.(s), [id]);
      const url = `${getSetting("BACKEND")}/api/v4/user/user/${id}`;
      return (
        <zeus-data-provider
          id={providerId}
          send-tags={`profile_client-${profileId}`}
        >
          <zeus-data-source-jsonapi
            url={url}
            type="user--user"
            data-action="update"
            attributes="mail,name"
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [id, providerId, profileId]);

  return {
    Provider,
    providerId,
    entity: data,
    isLoading,
    error,
    setData,
  };
}
