import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  addOutline,
  checkmarkOutline,
  closeOutline,
  trashOutline,
} from "ionicons/icons";
import {
  IonButton,
  IonIcon,
  IonList,
  IonPage,
  IonAlert,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonItemDivider,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonDatetime,
  IonDatetimeButton,
  IonChip,
} from "@ionic/react";
import dayjs from "dayjs";

import { pageConfig } from "../../settings";
import { useError, useSubscriptionAction } from "../../hooks";
import { useFormState, useDialogs } from "../../form/hooks";
import { FormItem } from "../../form/FormItem";
import { useProfileProvider } from "../../providers/profile-provider";
import { useUpdateEmailModal } from "../../modals/UpdateEmailModal";

function formatSubscriptionTitle(item) {
  if (item.type === "paragraph--subscription_subengine") {
    return "Abonnemang från mized.se";
  }
  if (item.type === "paragraph--subscription_google") {
    return "Abonnemang från Google Play";
  }
  if (item.type === "paragraph--subscription_apple") {
    return "Abonnemang från App Store";
  }
  if (item.type === "paragraph--subscription_manual") {
    return "Manuellt satt abonnemang";
  }

  return "Okänt";
}

function formatSubscriptionDates(item, user) {
  if (item.field_subscription_from && item.field_subscription_to) {
    const from = dayjs(item.field_subscription_from).format("YYYY-MM-DD");
    const to = dayjs(item.field_subscription_to).format("YYYY-MM-DD");
    return `${from} → ${to}${(user.field_sub_canceled && dayjs(item.field_subscription_to).unix() >= user.field_sub_canceled) ? `, Uppsagt` : ``}`;
  } else if (item.field_subscription_revalidate) {
    const from = dayjs(item.field_subscription_from).format("YYYY-MM-DD");
    const revalidate = dayjs(item.field_subscription_revalidate).format(
      "YYYY-MM-DD"
    );
    return `Start: ${from}, Förnyas: ${revalidate}`;
  }
}

const AdminUserEdit = () => {
  const { id } = useParams();
  const { Provider, entity, setData, isLoading, error } =
    useProfileProvider(id);
  const [formState, updateFormState, validateFormState] = useFormState(
    pageConfig.profile_client.fields,
    entity
  );
  const [subscriptionFrom, setSubscriptionFrom] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [subscriptionTo, setSubscriptionTo] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [modalOpen, setModalOpen] = useState(false);
  const { confirmDangerousAction } = useDialogs();
  const setValidationError = useError();
  const presentEmailModal = useUpdateEmailModal(
    entity?.field_user?.id,
    entity?.id
  );
  const doSubscriptionAction = useSubscriptionAction();

  const doSave = () => {
    const errors = validateFormState();
    if (errors.length) {
      setValidationError(errors[0]);
      return;
    }
    setData({ id, ...formState });
  };

  const doDeleteSub = (item) => {
    confirmDangerousAction({
      button: "Ta bort",
      message: "Är du säker på att du vill ta bort abonnemanget?",
      handler: () => {
        const newSubs = entity.field_subscriptions
          .filter((i) => i.id !== item.id)
          .map((i) => ({
            ...i,
            action: null,
          }));
        const data = {
          id,
          _paragraphs: [
            {
              field: "field_subscriptions",
              type: "paragraph--subscription_manual",
              values: newSubs,
            },
          ],
        };
        setData(data);
      },
    });
  };

  const doCancelSub = (item) => {
    confirmDangerousAction({
      button: "Säg upp",
      message: "Är du säker på att du vill säga upp abonnemanget omedelbart?",
      handler: () => {
        doSubscriptionAction("subscription_cancel", {
          paragraph_id: item.id,
          profile_id: id,
        });
      },
    });
  };

  const doAddSub = () => {
    setModalOpen(false);
    const newSubs = entity.field_subscriptions.map((i) => ({
      ...i,
      action: null,
    }));
    newSubs.push({
      action: "create",
      field_subscription_from: new Date(`${subscriptionFrom}T00:00:00`)
        .toISOString()
        .replace(/\.[0-9]{3}/, ""),
      field_subscription_to: new Date(`${subscriptionTo}T23:59:59`)
        .toISOString()
        .replace(/\.[0-9]{3}/, ""),
      field_subscription_active: true,
    });
    const data = {
      id,
      _paragraphs: [
        {
          field: "field_subscriptions",
          type: "paragraph--subscription_manual",
          attributes: [
            "field_subscription_active",
            "field_subscription_from",
            "field_subscription_to",
            "field_subscription_label",
          ],
          values: newSubs,
        },
      ],
    };
    setData(data);
  };

  const doOpenAddSub = () => {
    setSubscriptionFrom(dayjs().format("YYYY-MM-DD"));
    setSubscriptionTo(dayjs().format("YYYY-MM-DD"));
    setModalOpen(true);
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={error !== ""}
        message={error}
        header="Mized"
        buttons={["Ok"]}
      />
      <IonLoading isOpen={isLoading} />
      <IonModal isOpen={modalOpen}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="secondary">
              <IonButton onClick={() => setModalOpen(false)}>
                Stäng <IonIcon slot="end" icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>Lägg till abonnemang</IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={doAddSub}>
                Spara <IonIcon slot="end" icon={checkmarkOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList class="form">
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                locale="sv-SE"
                doneText="Ok"
                cancelText="Avbryt"
                showDefaultButtons={true}
                firstDayOfWeek={1}
                presentation="date"
                value={subscriptionFrom}
                id="datetime-modal-from"
                min={dayjs().subtract(5, "year").startOf("year").toISOString()}
                max={dayjs().add(5, "year").endOf("year").toISOString()}
                onIonChange={(e) => setSubscriptionFrom(e.detail.value)}
              />
            </IonModal>
            <IonItem>
              <IonLabel>Från:</IonLabel>
              <IonDatetimeButton datetime="datetime-modal-from" />
            </IonItem>
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                showDefaultButtons={true}
                doneText="Ok"
                cancelText="Avbryt"
                firstDayOfWeek={1}
                presentation="date"
                value={subscriptionTo}
                id="datetime-modal-to"
                min={dayjs().startOf("year").toISOString()}
                max={dayjs().add(10, "year").endOf("year").toISOString()}
                onIonChange={(e) => setSubscriptionTo(e.detail.value)}
              />
            </IonModal>
            <IonItem>
              <IonLabel>Till:</IonLabel>
              <IonDatetimeButton datetime="datetime-modal-to" />
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/c/admin/users`} />
          </IonButtons>
          <IonTitle>Redigera {entity?.field_name}</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={doSave}>
              Spara <IonIcon slot="end" icon={checkmarkOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList class="form">
          <IonItemDivider color="light">Konto</IonItemDivider>
          <IonItem button onClick={presentEmailModal}>
            <IonLabel>{entity?.field_user?.mail}</IonLabel>
            {entity?.field_profile_client_access?.name === "Premium" && (
              <IonChip slot="end">Premium</IonChip>
            )}
          </IonItem>
          <IonItemDivider color="light">Profil</IonItemDivider>
          {pageConfig.profile_client.fields.map((field) => (
            <FormItem
              key={field.field ?? field.label}
              label={field.label}
              type={field.type}
              config={field}
              value={formState[field.field]}
              onChange={(e) => updateFormState(field, e.detail.value)}
            />
          ))}
          <IonItemDivider color="light">Användarinfo</IonItemDivider>
          <IonItem>
            <IonLabel>
              Användarnivå: {entity?.field_profile_client_access?.name}
            </IonLabel>
          </IonItem>
          {entity?.field_sub_type && (
            <IonItem>
              <IonLabel>Källa till köp: {entity.field_sub_type}</IonLabel>
            </IonItem>
          )}

          <IonItemDivider color="light">Abonnemang</IonItemDivider>
          {entity?.field_subscriptions.map((item) => (
            <IonItem
              key={item.id}
              routerLink={
                item.type === "paragraph--subscription_subengine"
                  ? `/c/admin/subscription/${item.id}`
                  : null
              }
            >
              <IonLabel>
                <h2>{formatSubscriptionTitle(item)}</h2>
                <p>
                  Aktivt: {item.field_subscription_active ? "Ja" : "Nej"},{" "}
                  {formatSubscriptionDates(item, entity)}
                </p>
              </IonLabel>
              {item.type === "paragraph--subscription_manual" && (
                <IonButton
                  slot="end"
                  fill="clear"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    doDeleteSub(item);
                  }}
                >
                  <IonIcon slot="icon-only" icon={trashOutline} />
                </IonButton>
              )}
              {item.type === "paragraph--subscription_subengine" && (
                <IonButton
                  slot="end"
                  fill="clear"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    doCancelSub(item);
                  }}
                >
                  <IonIcon slot="icon-only" src="assets/icons/buster.svg" />
                </IonButton>
              )}
            </IonItem>
          ))}
          <IonItem button onClick={doOpenAddSub}>
            <IonLabel>Lägg till abonnemang</IonLabel>
            <IonIcon slot="end" icon={addOutline} />
          </IonItem>
        </IonList>
      </IonContent>
      <Provider />
    </IonPage>
  );
};

export default AdminUserEdit;
