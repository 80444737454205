import { useState, useEffect, useRef } from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonList,
  IonButton,
  IonIcon,
  IonLabel,
  IonSearchbar,
  IonSpinner,
  IonModal,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { pageConfig } from "../settings";
import { buildSearchUrl } from "./helpers";
import { useConnector } from "../connector-context";
import AppTagFilter from "./AppTagFilter";
import PreviewModal from "../modals/PreviewModal";

const ConnectorField = ({ target, onEntityAdd }) => {
  const bundle = target.type === "workout-blocks" ? "exercise" : target.bundle;
  const settings = pageConfig[bundle];
  const providerId = `connector-${target.field}`;

  const [searchTags, setSearchTags] = useState({});
  const [searchString, setSearchString] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchUrl, setSearchUrl] = useState("");
  const [previewId, setPreviewId] = useState("");
  const { data: entities, isLoading } = useZeusDataProvider(providerId, []);

  useEffect(() => {
    setSearchUrl(buildSearchUrl(bundle, searchString, searchTags));
  }, [bundle, searchString, searchTags]);

  const providerRef = useRef();
  const hasMore = providerRef.current?.source?.hasNextPage;

  const doLoadMore = () => {
    document.dispatchEvent(
      new CustomEvent(`zeusDataOperation:${providerId}`, {
        detail: { name: "next-page" },
      })
    );
  };

  const doAddEntity = (e, entity) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    onEntityAdd?.({ field: target, entity });
  };

  const doSearch = (e) => {
    e?.preventDefault?.();
    setSearchString(searchValue);
  };

  const doResetFilter = () => {
    setSearchString("");
    setSearchValue("");
    setSearchTags({});
  };

  return (
    <>
      <IonModal isOpen={previewId !== ""}>
        <PreviewModal
          id={previewId}
          type={bundle}
          onDone={() => setPreviewId("")}
        />
      </IonModal>
      <IonAccordion value={target.field}>
        <IonItem slot="header" color="light">
          {target.label}
        </IonItem>

        <IonList slot="content" className="ion-no-padding">
          <IonItem color="medium" lines="none">
            <form
              onSubmit={doSearch}
              action="/"
              className="connector-search-form"
            >
              <IonSearchbar
                className="connector-searchbar"
                placeholder="Sök"
                inputmode="search"
                value={searchValue}
                debounce={0}
                onIonChange={(e) => setSearchValue(e.target.value)}
              />
            </form>
          </IonItem>

          {settings.searchTags.map((tag, index, array) => (
            <AppTagFilter
              color="medium"
              lines={index !== array.length - 1 ? "inset" : "none"}
              key={tag.field}
              label={tag.label}
              provider={tag.provider}
              value={searchTags[tag.field] ?? ""}
              onChange={(value) =>
                setSearchTags((current) => ({
                  ...current,
                  [tag.field]: value,
                }))
              }
            />
          ))}

          <IonItem color="medium">
            <IonButton onClick={doResetFilter}>Återställ</IonButton>
          </IonItem>

          {entities.map((entity) => (
            <IonItem
              key={entity.id}
              button
              onClick={() => setPreviewId(entity.id)}
            >
              <IonLabel>{entity.title}</IonLabel>
              <IonButton
                slot="end"
                fill="clear"
                onClick={(e) => doAddEntity(e, entity)}
              >
                <IonIcon slot="icon-only" icon={add} />
              </IonButton>
            </IonItem>
          ))}

          {isLoading && (
            <div className="ion-text-center ion-padding">
              <IonSpinner />
            </div>
          )}

          {hasMore && (
            <div className="ion-text-center">
              <IonButton disabled={isLoading} onClick={doLoadMore}>
                Ladda fler
              </IonButton>
            </div>
          )}
        </IonList>

        <zeus-data-provider
          id={providerId}
          listen-tags={target.bundle}
          ref={providerRef}
        >
          <zeus-data-source-jsonapi url={searchUrl}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      </IonAccordion>
    </>
  );
};

const AppConnector = () => {
  const { connectorTargets, connectorCallback } = useConnector();

  const onEntityAdd = (conf) => {
    connectorCallback.cb(conf);
  };

  return (
    <IonAccordionGroup>
      {connectorTargets.map((target) => (
        <ConnectorField
          key={target.field}
          target={target}
          onEntityAdd={onEntityAdd}
        />
      ))}
    </IonAccordionGroup>
  );
};

export default AppConnector;
