import { checkmarkOutline } from "ionicons/icons";
import {
  IonButton,
  IonIcon,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonList,
  IonLoading,
} from "@ionic/react";
import { pageConfig } from "../../settings";

import { useFormState } from "../../form/hooks";
import { FormItem } from "../../form/FormItem";
import { useSettingsProvider } from "../../providers/settings-provider";

const AdminSettings = () => {
  const fields = pageConfig["settings"].fields;
  const { Provider, settings, isLoading, setData } = useSettingsProvider();
  const [formState, updateFormState] = useFormState(fields, settings);

  const doSave = () => {
    setData({ id: settings.id, ...formState });
  };

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <IonHeader>
        <IonToolbar>
          <IonTitle>Inställningar</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={doSave}>
              Spara <IonIcon slot="end" icon={checkmarkOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList class="form">
          {fields.map((field) => (
            <FormItem
              key={field.field ?? field.label}
              label={field.label}
              type={field.type}
              config={field}
              value={formState[field.field]}
              onChange={(e) => updateFormState(field, e.detail.value)}
            />
          ))}
        </IonList>
      </IonContent>
      <Provider />
    </IonPage>
  );
};

export default AdminSettings;
