import { useCallback } from "react";
import { IonLoading } from "@ionic/react";
import {
  useDynamicDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

const SubscriptionCancelAction = ({
  paragraph_id,
  profile_id,
  onDone = () => {},
}) => {
  useDynamicDataProvider("action-provider");
  const data = { paragraph_id, profile_id };
  useZeusDataStatusSubscriber(
    "action-provider",
    useCallback(
      (status) => {
        if (status === "updated") {
          onDone();
        }
      },
      [onDone]
    )
  );

  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} />
      <zeus-data-provider
        id="action-provider"
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags={`profile_client-${profile_id}`}
      >
        <zeus-data-source-json
          data-action="create"
          url={`${getSetting("BACKEND")}/api/subcancel?id=${paragraph_id}`}
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const SubscriptionActionModal = ({ action, args, onDone }) => {
  if (action === "subscription_cancel") {
    return <SubscriptionCancelAction {...args} onDone={onDone} />;
  }

  return null;
};

export default SubscriptionActionModal;
