import { useCallback, useState } from "react";
import { useIonAlert, useIonModal } from "@ionic/react";

import { getSetting } from "./settings";
import ActionModal from "./modals/ActionModal";
import SubscriptionActionModal from "./modals/SubscriptionActionModal";

export function useError() {
  const [presentAlert] = useIonAlert();
  const setError = (message) => {
    presentAlert({
      message,
      header: getSetting("APP_NAME"),
      buttons: ["Ok"],
    });
  };
  return setError;
}

export function useAlert() {
  const [presentAlert] = useIonAlert();

  const showAlert = useCallback(
    (message) => {
      return new Promise((resolve) => {
        presentAlert({
          header: getSetting("APP_NAME"),
          message,
          buttons: ["Ok"],
          onDidDismiss: resolve,
        });
      });
    },
    [presentAlert]
  );

  return showAlert;
}

export function useEntityAction(onDone) {
  const doCloseModal = () => {
    dismissModal();
    onDone && onDone("hej");
  };
  const [actionState, setActionState] = useState({
    action: "",
    type: "",
    id: "",
  });
  const [presentModal, dismissModal] = useIonModal(ActionModal, {
    ...actionState,
    onDone: doCloseModal,
  });

  const doEntityAction = useCallback(
    (action, type, id) => {
      setActionState({ action, type, id });
      presentModal({ backdropDismiss: false, cssClass: "action-modal" });
    },
    [presentModal]
  );

  return doEntityAction;
}

export function useSubscriptionAction(onDone) {
  const doCloseModal = (arg) => {
    dismissModal();
    onDone && onDone(arg);
  };
  const [actionState, setActionState] = useState({
    action: "",
    args: {},
  });
  const [presentModal, dismissModal] = useIonModal(SubscriptionActionModal, {
    ...actionState,
    onDone: doCloseModal,
  });

  const doEntityAction = useCallback(
    (action, args) => {
      setActionState({ action, args });
      presentModal({ backdropDismiss: false, cssClass: "action-modal" });
    },
    [presentModal]
  );

  return doEntityAction;
}
