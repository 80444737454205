import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting, pageConfig } from "../settings";
import { generateAttributeString } from "../form/helpers";

export function useSettingsProvider() {
  const apiRoot = `${getSetting("BACKEND")}/api/v4`;
  const url = `${apiRoot}/site_setting_entity/settings`;

  const { data: index, isLoading: indexIsLoading } =
    useZeusDataProvider(`site-settings-index`);
  const {
    data: settings,
    isLoading: entityIsLoading,
    setData,
  } = useZeusDataProvider(`site-settings-entity`);

  const settingsId = index?.length > 0 ? index[0].id : null;
  const isLoading = indexIsLoading || entityIsLoading;

  const Provider = useMemo(() => {
    const Component = () => {
      return (
        <>
          <zeus-data-provider id="site-settings-index">
            <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
            <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
          </zeus-data-provider>
          {settingsId && (
            <zeus-data-provider id="site-settings-entity">
              <zeus-data-source-jsonapi
                url={`${url}/${settingsId}`}
                type="site_setting_entity--settings"
                data-action="update"
                attributes={generateAttributeString(pageConfig.settings.fields)}
                api-root={apiRoot}
                include-type
              ></zeus-data-source-jsonapi>
              <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
            </zeus-data-provider>
          )}
        </>
      );
    };
    return Component;
  }, [url, settingsId, apiRoot]);

  return {
    Provider,
    settings,
    setData,
    isLoading,
  };
}
