import { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonList,
  IonItem,
  IonSpinner,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonChip,
  IonGrid,
  IonCol,
  IonRow,
  IonSearchbar,
  IonButton,
} from "@ionic/react";

import { useUsersProvider } from "../../providers/users-provider";
import AppTagFilter from "../../form/AppTagFilter";

const AdminUsers = () => {
  const [searchTags, setSearchTags] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchString, setSearchString] = useState("");

  const { Provider, users, isLoading } = useUsersProvider(
    searchString,
    searchTags
  );

  const isPremium = (item) => {
    return item.field_profile_client_access?.name === "Premium";
  };

  const doSearch = (e) => {
    e?.preventDefault();
    setSearchString(searchValue);
  };

  const resetFilter = () => {
    setSearchString("");
    setSearchValue("");
    setSearchTags({});
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Användare</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol>
              <form onSubmit={doSearch} action="/">
                <IonSearchbar
                  placeholder="Namn eller e-post"
                  inputmode="search"
                  value={searchValue}
                  debounce={0}
                  onIonChange={(e) => setSearchValue(e.target.value)}
                />
              </form>
            </IonCol>

            <IonCol>
              <AppTagFilter
                label="Access"
                provider="access"
                value={searchTags["field_profile_client_access"] ?? ""}
                onChange={(value) =>
                  setSearchTags({ field_profile_client_access: value })
                }
              />
            </IonCol>

            <IonCol size="auto">
              <IonButton onClick={resetFilter} fill="outline">
                Återställ
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonList>
          {isLoading && (
            <div className="ion-text-center">
              <IonSpinner />
            </div>
          )}
          {users.map((item) => (
            <IonItem key={item.id} routerLink={`/c/admin/users/${item.id}`}>
              <IonLabel>
                {item.field_name} {`<${item.field_user?.mail}>`}
              </IonLabel>
              {isPremium(item) && <IonChip>Premium</IonChip>}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
      <Provider />
    </IonPage>
  );
};

export default AdminUsers;
